import React from "react";
import { Container } from "components/library";
import { graphql } from "gatsby";
import { ProfileCard } from "components/pages/profile/profile-card";

const Info = () => {
  const details = {
    name: "An Ai Catalyst For Material Innovation",
    contact: {
      email: "contact@polymerize.io",
      phone: "",
      website: "www.polymerize.io",
      linkedin: "https://www.linkedin.com/company/polymerize-io/",
    },
    address: [
      "Polymerize Pte Ltd",
      "UEN 202013550M",
      "11 Irving Place, #09-01,",
      "Singapore 369551",
    ],
  };
  return (
    <main className="-mt-28 sm:-mt-36 pt-28 sm:pt-36 min-h-[600px] w-full shadow-md">
      <Container className="flex flex-col gap-20 pt-8 pb-20">
        {/* CONTENT */}
        <Container className="min-h-screen py-12 flex flex-col">
          <ProfileCard
            name={details.name as string}
            contact={details.contact}
            designation={""}
            image={""}
          />
        </Container>
      </Container>
    </main>
  );
};

export default Info;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
